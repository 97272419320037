import axios from '../../../services/api/baseUrl'
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { Link } from "react-router-dom";


function Category()
{

    const [loading, setLoading] = useState(true);
    const [speciality, setSpeciality] = useState([]);


    const getSpeciality = () => {
        axios.get("/specialites/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoading(false);
                setSpeciality(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getSpeciality();
    }, [])

   return(
    <div>
        <div className="section margin-top-65">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">

                        <div className="section-headline centered margin-bottom-15">
                            <h3>Catégories de spécialités populaires</h3>
                        </div>

                        <div className="categories-container">

                            {  loading  ? <div className="center">
                                            <CircularProgress/>
                                        </div> : 
                                        <div className="categories-container">
                                            {speciality.data.map(item => (
                                                <Link 
                                                                
                                                to={{
                                                    pathname: `/category/${item.specialite}/${item.idt_specialite}`,
                                                }}
                                            >
                                                 <div  key={item.idt_specialite} className="category-box">
                                                    <div className="category-box-icon">
                                                        <i className="icon-line-awesome-pencil"></i>
                                                    </div>
                                                    <div className="category-box-counter">{item.idt_specialite+1}</div>
                                                    <div className="category-box-content">
                                                        <h3>{item.specialite}</h3>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                                </Link>
                                                ))
                                            }

                                        </div>
                                    }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
   );
}

export default Category