import React, { useState, useEffect } from 'react';
import axios from '../../../services/api/baseUrl';
import background from "../../../assets/images/home-background-02.jpg";
import {useNavigate } from "react-router-dom";

function IntroBanner(){
    const [loading, setLoading] = useState(true);
    const [loadingTwo, setLoadingTwo] = useState(true);
    const [province, setProvince] = useState([]);
    const [specialiste, setSpecialiste] = useState([]);
    const [ville, setVille] = useState("");
    const [refSpecialite, setRefSpecialite] = useState("");

    let navigate = useNavigate();


    const getProvince = () => {
        axios.get("/public/province/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoading(false);
                setProvince(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const getSpecialiste = () => {
        axios.get("/specialites/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoadingTwo(false);
                setSpecialiste(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleChangeVille = (event) => {
        setVille(event.target.value);
    };

    const handleChange = (event) => {
        setRefSpecialite(event.target.value);
    };

    useEffect(() => {
        getProvince();
        getSpecialiste();
    }, [])

    const openSearchPage = () => {
        if(ville !== "" && refSpecialite !== ""){
            navigate(`/search/${refSpecialite}/${ville}`);
        }else{
            alert('Veuillez séléctionner une ville et la spécialité avant de rechercher.');
        }
    }

    return(
        <div>
            <div className="intro-banner dark-overlay" data-background-image={background}>
                <div className="container">
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-headline">
                                <h3>
                                    <strong>Engagez des experts ou soyez embauché pour n'importe quel travail, à tout moment.</strong>
                                    <br/>
                                    <span>Des milliers de petites entreprises utilisent <strong className="color">Réseaux d'experts</strong> pour transformer leurs idées en réalité.</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="intro-banner-search-form margin-top-95">

                                <div className="intro-search-field with-autocomplete">
                                    <label htmlFor="autocomplete-input" className="field-title ripple-effect">Où ?</label>
                                    <div className="input-with-icon">
                                        <select onChange={handleChangeVille}>
                                        {  
                                            loading  ?  <option>Ville</option> : 
                                            <>
                                                <option>Ville</option>
                                                {province.data.map(item => (
                                                    <option key={item.idt_provinces}>{item.province}</option>
                                                ))}
                                            </>
                                        }
                                        </select>
                                        <i className="icon-material-outline-location-on"></i>
                                    </div>
                                </div>

                                <div className="intro-search-field">
                                    <label htmlFor ="intro-keywords" className="field-title ripple-effect">Quelle spécialiste voulez-vous?</label>
                                    <select onChange={handleChange}>
                                        {  
                                            loadingTwo  ? <option>Spécialité</option> : 
                                            <>
                                                <option>Spécialité</option>
                                                {specialiste.data.map(item => (
                                                    <option key={item.idt_specialite} value={item.idt_specialite}>{item.specialite}</option>
                                                ))}
                                            </>
                                        }
                                        </select>
                                </div>

                                <div className="intro-search-button">
                                    <button onClick={openSearchPage} className="button ripple-effect">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ul className="intro-stats margin-top-45 hide-under-992px">
                                <li>
                                    <strong className="counter">1,586</strong>
                                    <span>Jobs Postés</span>
                                </li>
                                <li>
                                    <strong className="counter">3,543</strong>
                                    <span>Tâches Postées</span>
                                </li>
                                <li>
                                    <strong className="counter">1,232</strong>
                                    <span>Experts</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );

}

export default IntroBanner