import {Helmet} from "react-helmet";

function HelmetMeta(props)
{
    return(
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.title}</title>
            <meta name="description" content="Reseaux d'experts congolais application." />
            <link rel="canonical" href="https://e-solutions-rdc.com/r-experts" />
        </Helmet>
    );
}

export default HelmetMeta