import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/user-avatar-small-01.jpg';


function Header()
{

    return(
        <div>
           
            <header id="header-container" className="fullwidth">
                <div id="header">
                    <div className="container">
                        <div className="left-side">
                        
                            <div id="logo">
                                <a ><img src={logo} alt="logo"/></a>
                            </div>

                            <nav id="navigation">
                                <ul id="responsive">
                                    <li><Link to="/">Accueil</Link></li>
                                    <li><a>Compte</a>
                                        <ul class="dropdown-nav">
                                            <li><a target={'t_blank'} href={'https://expert-app.e-solutions-rdc.com'}><span>Se connecter</span></a></li>
                                            <li><Link to="/inscription"><span>S'inscrire</span></Link></li>
                                            <li><Link to="/plainte"><span>Plainte client</span></Link></li>
                                        </ul>
                                    </li>
                                    <li className="active"><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/about"><span>A Propos</span></Link></li>
                                </ul>
                            </nav>
                            <div className="clearfix"></div>
                            
                        </div>

                        <div className="right-side">
                            <div className="header-widget hide-on-mobile">
                            
                                <div className="header-notifications">
                                    <div className="header-notifications-trigger">
                                        <a href="#"><i className="icon-feather-bell"></i><span>4</span></a>
                                    </div>

                                    <div className="header-notifications-dropdown">

                                        <div className="header-notifications-headline">
                                            <h4>Notifications</h4>
                                            <button className="mark-as-read ripple-effect-dark" title="Mark all as read" data-tippy-placement="left">
                                                <i className="icon-feather-check-square"></i>
                                            </button>
                                        </div>

                                        <div className="header-notifications-content">
                                            <div className="header-notifications-scroll" data-simplebar>
                                                <ul>
                                                    <li className="notifications-not-read">
                                                        <a href="dashboard-manage-candidates.html">
                                                            <span className="notification-icon"><i className="icon-material-outline-group"></i></span>
                                                            <span className="notification-text">
                                                                <strong>Michael Shannah</strong> applied for a job <span className="color">Full Stack Software Engineer</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="header-widget">
                                <div className="header-notifications user-menu">
                                    <div className="header-notifications-trigger">
                                        <div className="user-avatar status-online"><img src={avatar} alt=""/></div>
                                    </div>
                                    <div className="header-notifications-dropdown">
                                        <div className="user-status">
                                            <div className="user-details">
                                                <div className="user-avatar status-online"><img src={avatar} alt=""/></div>
                                                <div className="user-name">
                                                    Espace <span>Client/Expert</span>
                                                </div>
                                            </div>
                                            
                                            <div className="status-switch" id="snackbar-user-status">
                                                <label className="user-online current-status">Online</label>
                                                <label className="user-invisible">Invisible</label>
                                                <span className="status-indicator" aria-hidden="true"></span>
                                            </div>	
                                    </div>
                                    
                                    <ul className="user-menu-small-nav">
                                        <li><i className="icon-material-outline-dashboard"></i>Dashboard</li>
                                        <li><i className="icon-material-outline-settings"></i>Plainte</li>
                                    </ul>

                                    </div>
                                </div>

                            </div>

                            <span className="mmenu-trigger">
                                <button className="hamburger hamburger--collapse" type="button">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </span>

                        </div>

                    </div>
                </div>
                </header>
                <div className="clearfix"></div>
        </div>
    );
}

export default Header