function Footer(){

    return (
        <div>
            <div id="footer">
                
                <div className="footer-top-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">

                                <div className="footer-rows-container">
                                    
                                    <div className="footer-rows-left">
                                        <div className="footer-row">
                                            <div className="footer-row-inner footer-logo">
                                                <img src="images/logo2.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="footer-rows-right">

                                        <div className="footer-row">
                                            <div className="footer-row-inner">
                                                <ul className="footer-social-links">
                                                    <li>
                                                        <a href="#" title="Facebook" data-tippy-placement="bottom" data-tippy-theme="light">
                                                            <i className="icon-brand-facebook-f"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Twitter" data-tippy-placement="bottom" data-tippy-theme="light">
                                                            <i className="icon-brand-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Google Plus" data-tippy-placement="bottom" data-tippy-theme="light">
                                                            <i className="icon-brand-google-plus-g"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="LinkedIn" data-tippy-placement="bottom" data-tippy-theme="light">
                                                            <i className="icon-brand-linkedin-in"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="footer-row">
                                            <div className="footer-row-inner">
                                                <select className="selectpicker language-switcher" data-selected-text-format="count" data-size="5">
                                                    <option>Français</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-middle-section">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-2 col-lg-2 col-md-3">
                                <div className="footer-links">
                                    <h3>Pour Experts</h3>
                                    <ul>
                                        <li><a href="#"><span>Parcourir les Métiers</span></a></li>
                                        <li><a href="#"><span>Ajouter un CV</span></a></li>
                                        <li><a href="#"><span>Alertes emploi</span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-3">
                                <div className="footer-links">
                                    <h3>Pour Client</h3>
                                    <ul>
                                        <li><a href="#"><span>Parcourir les experts</span></a></li>
                                        <li><a href="#"><span>Publier une offre</span></a></li>
                                        <li><a href="#"><span>Publier une tâche</span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-3">
                                <div className="footer-links">
                                    <h3>Liens utiles</h3>
                                    <ul>
                                        <li><a href="#"><span>Contact</span></a></li>
                                        <li><a href="#"><span>Politique de confidentialité</span></a></li>
                                        <li><a href="#"><span>Conditions d'utilisation</span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-3">
                                <div className="footer-links">
                                    <h3>Compte</h3>
                                    <ul>
                                        <li><a href="#"><span>Connexion</span></a></li>
                                        <li><a href="#"><span>Mon compte</span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-12">
                                <h3><i className="icon-feather-mail"></i> Inscrivez-vous à une newsletter</h3>
                                <p>Dernières nouvelles hebdomadaires, analyses et conseils de pointe sur la recherche d'emploi.</p>
                                <form action="#" method="get" className="newsletter">
                                    <input type="text" name="fname" placeholder="Enter your email address"/>
                                    <button type="submit"><i className="icon-feather-arrow-right"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="footer-bottom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                © 2022-2023 <strong>E-solutions-rdc</strong>. All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer