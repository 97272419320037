import axios from '../../../services/api/baseUrl'
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';

function Experts()
{

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const getExperts = () => {
        axios.get("/public/experts/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoading(false);
                setData(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getExperts();
    }, [])



    return(
            <div className="section gray padding-top-65 padding-bottom-70 full-width-carousel-fix">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="section-headline margin-top-0 margin-bottom-25">
                                <h3>Les Experts les mieux notés</h3>
                                <a href="#" className="headline-link">Parcourir tous les experts</a>
                            </div>
                        </div>

                        <div className="col-xl-12">
                        
                            {  loading  ? <div className="center">
                                            <CircularProgress/>
                                        </div> : 
                                        <div className="default-slick-carousel freelancers-container freelancers-grid-layout">
                                            {data.data.map(item => (
                                                
                                                <div className="freelancer" key={item.idt_users}>
                                                    <div className="freelancer-overview">
                                                        <div className="freelancer-overview-inner">
                                                            
                                                            <span className="bookmark-icon"></span>
                                                            
                                                            <div className="freelancer-avatar">
                                                                <div className="verified-badge"></div>
                                                                <a><img src="images/user-avatar-placeholder.png" alt=""/></a>
                                                            </div>
                
                                                            <div className="freelancer-name">
                                                                <h4><a >s<img className="flag" src="images/flags/cd.svg" alt="" title="United Kingdom" data-tippy-placement="top"/></a></h4>
                                                                <span>UI/UX Designer</span>
                                                            </div>
                
                                                            <div className="freelancer-rating">
                                                                <div className="star-rating" data-rating={item.idt_users+1+".0"}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="freelancer-details">
                                                        <div className="freelancer-details-list">
                                                            <ul>
                                                                <li>Location <strong><i className="icon-material-outline-location-on"></i> London</strong></li>
                                                                <li>Rate <strong>$60 / hr</strong></li>
                                                                <li>Job Success <strong>95%</strong></li>
                                                            </ul>
                                                        </div>
                                                        <a  className="button button-sliding-icon ripple-effect">View Profile <i className="icon-material-outline-arrow-right-alt"></i></a>
                                                    </div>
                                                </div>
                                                ))
                                            }

                                        </div>
                                    }

                            </div>
                        </div>
                </div>
            </div>
    );
}

export default Experts