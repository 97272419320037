import Header from "../../components/header/header"
import React, { useState, useEffect } from 'react';
import axios from "../../services/api/baseUrl"
import { useParams } from 'react-router-dom';
import CircularUnderLoad from "../../components/circularUnderload";
import HelmetMeta from "../../components/metas/helmet_meta";
import TagComponent from '../../components/sidebar/tagComponent';
import avatar from '../../assets/images/user-avatar-small-01.jpg';
import { Link } from "react-router-dom";

function Search(){

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const { job, emplacement } = useParams();

    const getExperts = () => {
        axios.get(`/public/specialites/experts/search?specialite=${job}`)
        .then(res => 
        {
            if (res.data)
            {
                setLoading(false);
                setData(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getExperts();
    }, []);

    if(loading){
        return(
            <>
                <center className="center">
                    <br/><br/>
                    <CircularUnderLoad/>
                </center>
            </>
        );
    }

    return(
        <>
            <HelmetMeta title={"Recherche expert à "+emplacement}/>
            <Header/>
            <div className="full-page-container">

                <TagComponent/>
                
                <div className="full-page-content-container" data-simplebar>
                    <div className="full-page-content-inner">

                        <h3 className="page-title">Résultats de recherche expert dans la ville de <b>{emplacement}</b></h3>

                        <div className="notify-box margin-top-15">
                            <div className="switch-container">
                                <label className="switch"><input type="checkbox"/><span className="switch-button"></span><span className="switch-text">Turn on email alerts for this search</span></label>
                            </div>

                            <div className="sort-by">
                                <span>Trier par:</span>
                                <select className="selectpicker hide-tick">
                                    <option>Relevance</option>
                                    <option>Newest</option>
                                    <option>Oldest</option>
                                    <option>Random</option>
                                </select>
                            </div>
                        </div>

                        <div className="listings-container grid-layout margin-top-35">

                                        <>
                                            {data.data.map(item => (
                                                 <div className="job-listing" key={item.idt_job_specialite}>
                                                    <div className="job-listing-details">
                                                    <Link 
                                                            
                                                            to={{
                                                                pathname: `/expert/${item.profile.idt_users}`,
                                                                state: {users: item }
                                                            }}
                                                        >
                                                        <div className="job-listing-company-logo">
                                                            <img src={item.profile.avatar === null ? avatar : item.profile.avatar} alt=""/>
                                                        </div>

                                                        <div className="job-listing-description">
                                                            <h4 className="job-listing-company">{item.specialite.specialite} <span className="verified-badge" title="Verified Employer" data-tippy-placement="top"></span></h4>
                                                            <h3 className="job-listing-title">{item.profile.noms}</h3>
                                                        </div>
                                                    </Link>
                                                    </div>

                                                    <div className="job-listing-footer">
                                                        <span className="bookmark-icon"></span>
                                                        <ul>
                                                            <li><i className="icon-material-outline-location-on"></i>{item.profile.ville}</li>
                                                            <li><i className="icon-material-outline-business-center"></i>Disponible</li>
                                                            <li><i className="icon-material-outline-account-balance-wallet"></i>$100-$1500</li>
                                                            <li><i className="icon-material-outline-access-call"></i>Tél : {item.profile.phone}</li>
                                                        </ul>
                                                    </div>
                                                </div>	
                                                ))
                                            }

                                        </>

                        </div>

                       {/* <div className="clearfix"></div>
                        <div className="pagination-container margin-top-20 margin-bottom-20">
                            <nav className="pagination">
                                <ul>
                                    <li className="pagination-arrow"><a href="#" className="ripple-effect"><i className="icon-material-outline-keyboard-arrow-left"></i></a></li>
                                    <li><a href="#" className="ripple-effect">1</a></li>
                                    <li><a href="#" className="ripple-effect current-page">2</a></li>
                                    <li><a href="#" className="ripple-effect">3</a></li>
                                    <li><a href="#" className="ripple-effect">4</a></li>
                                    <li className="pagination-arrow"><a href="#" className="ripple-effect"><i className="icon-material-outline-keyboard-arrow-right"></i></a></li>
                                </ul>
                            </nav>
                        </div>
                                        <div className="clearfix"></div>*/ }
                        

                    </div>
                  
                </div>
          </div>
        </>
    );
}

export default Search