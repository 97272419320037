import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NotFond from './pages/404/404';
import Home from './pages/accueil/home';
import Profile from './pages/experts/profile';
import ProfileByCategory from './pages/experts/profileByCategory';
import ContactExpert from './pages/contact/expert';
import Inscription from './pages/inscription/inscription';
import Search from './pages/search/search';
import Contact from './pages/contact/contact';

function RoutesMain() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/home' element={<Home />} />
                <Route exact path='/inscription' element={<Inscription/>}/>
                <Route path={"/expert/:id"} element={<Profile />} />
                <Route path={"/search/:job/:emplacement"} element={<Search/>} />
                <Route path={"/category/:profile/:id"} element={<ProfileByCategory/>} />
                <Route path={"/contact/expert/:id"} element={<ContactExpert/>} />
                <Route path={"/contact"} element={<Contact/>} />
                <Route path='*' element={<NotFond/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesMain