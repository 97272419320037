import Header from "../../components/header/header";
import HelmetMeta from '../../components/metas/helmet_meta';
import React, { useState, useEffect } from 'react';
import swal from "sweetalert";
import { CircularProgress } from "@mui/material";
import axios from "../../services/api/baseUrl";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../services/api/firebase";
import Footer from "../../components/footer/footer";

function Inscription(){

    const [loading, setLoading] = useState(false);
    const [loadingVille, setLoadingVille] = useState(true);
    const [loadingCommune, setLoadingCommune] = useState(true);
    const [province, setProvince] = useState([]);
    const [communeData, setCommuneData] = useState([]);
    

    //Data from from
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [phone, setPhone] = useState('');
    const [sexe, setSexe] = useState('');
    const [commune, setCommune] = useState('');
    const [rue, setRue] = useState('');
    const [ville, setVille] = useState('');
    const [isExpert, setExpert] = useState(true);
    const [percent, setPercent] = useState(0);
    const [file, setFile] = useState("");

    const getProvince = () => {
        axios.get("/public/province/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoadingVille(false);
                setProvince(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const getCommune = () => {
        axios.get("/public/fetch/commune?province=1")
        .then(res => 
        {
            if (res.data)
            {
                setLoadingCommune(false);
                setCommuneData(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }
    

    const handleIsExpert = () => {
        setExpert(true);
    };
    
    const handleNotExpert = () => {
        setExpert(false);
    };

    const handleChangeSexe = (event) => {
        setSexe(event.target.value);
    };

    const handleChangeVille = (event) => {
        setVille(event.target.value);
    };

    const handleChangeCommune = (event) => {
        setCommune(event.target.value);
    };

    // Handle file upload event and update state
    function handleChange(event) {
        setFile(event.target.files[0]);
    }

    const submitData = () => {
   
        if (password !== "" && passwordRepeat !== "" && email !== "" && name !== "" && sexe !== "" && ville !== "" && commune !== "" && rue !== "") {
            
            if(file === "")
            {
                alert('Veuillez choisir une photo de profile.');

                return;
            }

            if(password === passwordRepeat){
                setLoading(true);

            
                    const storageRef = ref(storage, `/profiles/${file.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const percent = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            // update progress
                            setPercent(percent);
                        },
                        (err) => console.log(err),
                        () => {
                            // download url
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            
                                const data = {
                                    username: username,
                                    noms: name,
                                    email: email,
                                    phone: phone,
                                    password: password,
                                    sexe: sexe,
                                    rue: rue,
                                    commune: commune,
                                    ville: ville,
                                    avatar: url
                                }; 
                                if(isExpert)
                                {
                                    axios.post(`/accounts/register/expert`, data)
                                    .then(function (json) {
                                        if(json.data.success === true){
                                            swal({ title: "Succès", icon: 'success', text: `Merci de votre inscription. Votre compte en attente d'activation.`});
                                            cleanText();
                                            setLoading(false);
                                        }else{
                                            swal({ title: "Avertissement", icon: 'warning', text: json.message });
                                            setLoading(false);
                                        }
                                    }).catch(function (error) {
                                        swal({ title: "Erreur", icon: 'error', text: error });
                                        setLoading(false);
                                    });  
                                }else{

                                    axios.post(`/accounts/register/client`, data)
                                    .then(function (json) {

                                        if(json.data.success === true){
                                            swal({ title: "Succès", icon: 'success', text: `Merci de votre inscription. Votre compte en attente d'activation.`});
                                            cleanText();
                                            setLoading(false);
                                        }else{
                                            swal({ title: "Avertissement", icon: 'warning', text: json.message });
                                            setLoading(false);
                                        }
                                    }).catch(function (error) {
                                        swal({ title: "Erreur", icon: 'error', text: error });
                                        setLoading(false);
                                    });   
                                }
            
                            });
                        }
                    );

            }else{
                alert('Le premier mot de passe ne correspond pas au second. Veuillez réessayer.')
            }
                    
        } else {
            alert('Veuillez vérifier tous les champs.')
        }
        
    }

    useEffect(() => {
        getProvince();
        
        getCommune();      
    }, [])

    const cleanText = () =>
    {
        setName("");
        setUsername("");
        setEmail("");
        setPassword("");
        setPasswordRepeat("");
        setPhone("");
        setSexe("");
        setVille('');
        setRue('');
        setCommune('');
    }
   
    return(
        <>
            <Header/>
            <HelmetMeta title={"Créons votre compte !"}/>
            <br/>
            <div className="container">
                <div className="row dark-overlay">
                    <div className="col-xl-5 offset-xl-3">

                        <div className="login-register-page">
                            <div className="welcome-text">
                                <h3>Créons votre compte !</h3>
                            </div>

                            <div className="account-type">
                                <div>
                                    <input type="radio" name="account-type-radio" id="freelancer-radio" className="account-type-radio" checked/>
                                    <label onClick={handleIsExpert} for="freelancer-radio" className="ripple-effect-dark"><i className="icon-material-outline-account-circle"></i> Expert</label>
                                </div>

                                <div>
                                    <input type="radio" name="account-type-radio" id="employer-radio" class="account-type-radio"/>
                                    <label onClick={handleNotExpert} for="employer-radio" class="ripple-effect-dark"><i class="icon-material-outline-business-center"></i>Client</label>
                                </div>
                            </div>
                                
                            <form method="post" id="register-account-form">
                                <div className="input-with-icon-left">
                                    <i className="icon-material-outline-account-circle"></i>
                                    <input onChange={(e) => setName(e.target.value)} value={name} type="text" className="input-text with-border" name="name-register" id="name-register" placeholder="Entrez votre identité" required/>
                                </div>

                                <div className="input-with-icon">
                                    <select onChange={handleChangeSexe}>
                                        {  
                                            <>
                                                <option>Sexe</option>
                                                <option value={"M"}>Masculin</option>
                                                <option value={"F"}>Feminin</option>
                                            </>     
                                        }
                                    </select>
                                </div>

                                <div className="input-with-icon-left">
                                    <i className="icon-material-outline-account-circle"></i>
                                    <input onChange={(e) => setUsername(e.target.value)} value={username} type="text" className="input-text with-border" name="username-register" id="username-register" placeholder="Nom d'utilisateur" required/>
                                </div>

                                <div className="input-with-icon-left">
                                    <i className="icon-material-outline-phone-circle"></i>
                                    <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" className="input-text with-border" name="phone-register" id="phone-register" placeholder="Entrez votre numéro de téléphone" required/>
                                </div>

                                <div className="input-with-icon-left">
                                    <i className="icon-material-baseline-mail-outline"></i>
                                    <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" className="input-text with-border" name="emailaddress-register" id="emailaddress-register" placeholder="Adresse e-mail" required/>
                                </div>

                                
                                <div className="input-with-icon">
                                    <select onChange={handleChangeVille}>
                                        {  
                                            loadingVille  ?  <option>Ville</option> : 
                                            <>
                                                {province.data.map(item => (
                                                    <option value={item.idt_provinces} key={item.idt_provinces}>{item.province}</option>
                                                ))}
                                            </>
                                        }
                                    </select>
                                </div>

                                <div className="input-with-icon">
                                    <select onChange={handleChangeCommune}>
                                        {  
                                            loadingCommune ?  <option>Commune</option> : 
                                            <>
                                                {communeData.data.map(item => (
                                                    <option value={item.id} key={item.id}>{item.ville}</option>
                                                ))}
                                            </>
                                        }
                                    </select>
                                </div>
                                
                                <div className="input-with-icon-left" title="Doit contenir au moins 8 caractères" data-tippy-placement="bottom">
                                    <i className="icon-material-outline-lock"></i>
                                    <input onChange={(e) => setRue(e.target.value)} value={rue} type="text" className="input-text with-border" name="rue-register"  placeholder="Entrez votre rue/avenue" required/>
                                </div>

                                <div className="input-with-icon-left" title="Doit contenir au moins 8 caractères" data-tippy-placement="bottom">
                                    <i className="icon-material-outline-lock"></i>
                                    <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" className="input-text with-border" name="password-register" id="password-register" placeholder="Mot de passe" required/>
                                </div>

                                <div className="input-with-icon-left">
                                    <i className="icon-material-outline-lock"></i>
                                    <input onChange={(e) => setPasswordRepeat(e.target.value)} value={passwordRepeat} type="password" name="password-repeat-register" id="password-repeat-register" placeholder="Répéter le mot de passe" required/>
                                </div>

                                <div className="uploadButton margin-top-30">
                                    <input className="uploadButton-input" type="file" accept="image/*" id="upload" multiple onChange={handleChange}/>
                                    <label className="uploadButton-button ripple-effect" for="upload">Choisir</label>
                                    <span className="uploadButton-file-name">Ajouter une photo de profile.</span>
                                </div>
                            </form>
                            
                            {
                                loading ? <CircularProgress variant="determinate" value={percent}/> : 
                                <button onClick={submitData} className="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit" form="login-form">S'inscrire <i className="icon-material-outline-arrow-right-alt"></i></button>
                            }
                            <div className="col-xl-12">
                             {loading ? <p>{percent}% progression...</p> : <p></p>}
                            </div>
                            
                            
                        </div>

                    </div>
                </div>
            </div>

            <div className="margin-top-70"></div>
            <Footer/>
        </>
    );
}

export default Inscription;