import logo from './logo.svg';
import './App.css';
import RoutesMain from './router';

function App() {
  return (
    <div>
        <RoutesMain/>
    </div>
  );
}

export default App;
