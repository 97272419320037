import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyCD_WAt6rrufXL3u5cZg53gjJ183UKYgIo",
    authDomain: "invitation-one.firebaseapp.com",
    projectId: "invitation-one",
    storageBucket: "invitation-one.appspot.com",
    messagingSenderId: "941800846412",
    appId: "1:941800846412:web:899fb9b0685247cb5930e9",
    measurementId: "G-X5ETJN6JJC"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;