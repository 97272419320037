import Header from "../../components/header/header";
import React, { useState, useEffect } from 'react';
import axios from "../../services/api/baseUrl";
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Footer from "../../components/footer/footer";
import CircularUnderLoad from "../../components/circularUnderload";
import HelmetMeta from "../../components/metas/helmet_meta";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import swal from "sweetalert";
import background from '../../assets/images/single-freelancer.jpg';
import avatar from '../../assets/images/user-avatar-small-01.jpg';

const Profile  = () =>{

    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(true);
    const [loadComment, setLoadComment] = useState(true);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [specialites, setSpecialites] = useState([]);
    const [state, setState] = useState({
        snack: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, snack } = state;
    const [commentaires, setCommentaires] = useState([]);
    const [message, setMessage] = useState('');
    const { id } = useParams();

    //Data form 
    const [commentaire, setCommentaire] = useState('');
    const [identite, setIdentite] = useState('');
    const [adresse, setAdresse] = useState('');
    const [contact, setContact] = useState('');

    let navigate = useNavigate();


    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const snackClick = (newState) => () => {
        setState({ snack: true, ...newState });
      };
    
    const snackClose = () => {
        setState({ ...state, snack: false });
    };
    
    const getExpert = () => { 
        axios.get(`/public/experts/${id}`)
        .then(res => 
        {
            if (res.data)
            {
                setData(res.data);
                setLoading(false);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const getSpecialiteExpert = () => {
        axios.get(`/public/specialites/byexpert?user=${id}`)
        .then(res => 
        {
            if (res.data)
            {
                setSpecialites(res.data);
                setLoad(false);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const getComment = () => {
        axios.get(`/public/commentaires/expert/getComment?expert=${id}`)
        .then(res => 
        {
            if (res.data)
            {
                setCommentaires(res.data);
                setLoadComment(false);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const openContactPage = () => {
        if(id !== "" ){
            navigate(`/contact/expert/${id}`);
        }else{
            alert('Veuillez séléctionner l expert.');
        }
    }

    useEffect(() => {
        getExpert();
        getSpecialiteExpert();
        getComment();
    }, []);

    const submitData = () => {
   
        if (commentaire !== "" && adresse !== "" && contact !== "" && identite !== "") {
            handleClose();

            const data = {
                commentaire: commentaire,
                identite: identite,
                phone_or_email: contact,
                adresse: adresse,
                ref_user: id
            };   
            
            axios.post('/public/commentaires/add', data)
            .then(function (json) {
                if(json.data.success == true){
                    swal({ title: "Succès", icon: 'success', text: `Commentaire partagé avec succès.` });
                    cleanCommentaire();
                    getComment();
                }else{
                    swal({ title: "Avertissement", icon: 'warning', text: json.data.message });
                }
            }).catch(function (error) {
                swal({ title: "Erreur", icon: 'error', text: "Une erreur inconnue s'est produite. Réessayez plutard" });
            });

        } else {
            alert('Veuillez remplire tous les champs.')
        }
        
    }

    if(loading && load){
        return(
            <>
                <center className="center">
                    <br/><br/>
                    <CircularUnderLoad/>
                </center>
            </>
        );
    }

    const cleanCommentaire = () =>
    {
        setCommentaire("");
    }

    return(
        <div>
            <HelmetMeta title={"Profile expert"}/>
            <Header/>
            <div className="single-page-header freelancer-header" data-background-image={background}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                            {loading  ? <div className="center">
                                            <Skeleton
                                                sx={{ bgcolor: 'grey.900' }}
                                                variant="rectangular"
                                                height={118}
                                            />
                                        </div> :  
                                
                                    <div className="left-side">
                                        <div className="header-image freelancer-avatar"><img src={data.data.avatar == null ? avatar : data.data.avatar} alt=""/></div>
                                        <div className="header-details">
                                            <h3>{data.data.noms}{" "}<span>{data.data.phone}{"/"}{data.data.email}</span></h3>
                                            <ul>
                                                <li><div className="star-rating" data-rating="5.0"></div></li>
                                                <li><img className="flag" src="images/flags/de.svg" alt=""/> {data.data.ville}{"/"}{data.data.commune}{" "}</li>
                                                <li><div className="verified-badge-with-title">Verifié.e</div></li>
                                            </ul>
                                        </div>
                                    </div>
                               
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 content-right-offset">
                    
                        <div className="single-page-section">
                            <h3 className="margin-bottom-25">A Propos</h3>

                            <p>{loading ? "" : data.data.biographie}</p>
                        </div>

                        <div className="boxed-list margin-bottom-60">
                            <div className="boxed-list-headline">
                                <h3><i className="icon-material-outline-thumb-up"></i>Spécialités</h3>
                            </div>
                            <ul className="boxed-list-ul">
                                {load ? <CircularUnderLoad/> : specialites.data.map(item => 
                                    (
                                        <li key={item.idt_job_specialite}>
                                            <div className="boxed-list-item">
                                                <div className="item-content">
                                                    <h4>{item.specialite.specialite}</h4>
                                                    <div className="item-details margin-top-10">
                                                        <div className="star-rating" data-rating="5.0"></div>
                                                        <div className="detail-item"><i className="icon-material-outline-date-range"></i>{item.id_professionel}</div>
                                                    </div>
                                                    <div className="item-description">
                                                        <p>{item.specialite.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                              
                            </ul>

                        </div>
                        
                        <div className="boxed-list margin-bottom-60">
                            <div className="boxed-list-headline">
                                <h3><i className="icon-material-outline-business"></i> Commentaires</h3>
                                <div className="keywords-container">
                                    <div className="keyword-input-container">
                                        <textarea onChange={(e) => setCommentaire(e.target.value)} value={commentaire} className="keyword-input" placeholder="Avez-vous un truc à dire ?"></textarea>
                                        <button onClick={handleClickOpen} className="keyword-input-button ripple-effect"><i className="icon-material-outline-add"></i></button>
                                    </div>
                                    <div className="keywords-list"></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <ul className="boxed-list-ul">
                                {loadComment ?  
                                        <center className="center">
                                            <br/><br/>
                                            <CircularUnderLoad/>
                                        </center> : commentaires.data.map(item => 
                                    (
                                        <li key={item.idt_commentaire}>
                                            <div className="boxed-list-item">
                                                <div className="item-image">
                                                    <img src={avatar} alt=""/>
                                                </div>
                                                
                                                <div className="item-content">
                                                    <h4>{item.identite}</h4>
                                                    <div className="item-details margin-top-7">
                                                        <div className="detail-item"><a href="#"><i className="icon-material-outline-business"></i> {item.adresse}</a></div>
                                                        <div className="detail-item"><i className="icon-material-outline-date-range"></i>{item.created_at}</div>
                                                    </div>
                                                    <div className="item-description">
                                                        <p>{item.commentaire}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                    </div>
                    
                    <div className="col-xl-4 col-lg-4">
                        <div className="sidebar-container">
                            <a onClick={openContactPage} className="apply-now-button popup-with-zoom-anim margin-bottom-50">Contacter <i className="icon-material-outline-arrow-right-alt"></i></a>
                            
                            <div className="sidebar-widget">
                                <h3>Réseaux sociaux</h3>
                                <div className="freelancer-socials margin-top-25">
                                    <ul>
                                        <li><a href="#" title="Dribbble" data-tippy-placement="top"><i className="icon-brand-dribbble"></i></a></li>
                                        <li><a href="#" title="Twitter" data-tippy-placement="top"><i className="icon-brand-twitter"></i></a></li>
                                        <li><a href="#" title="Behance" data-tippy-placement="top"><i className="icon-brand-behance"></i></a></li>
                                        <li><a href="#" title="GitHub" data-tippy-placement="top"><i className="icon-brand-github"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className="margin-top-15"></div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Commentaire</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Veuillez nous renseigner votre identite et adresse e-mail ou numéro de téléphone
                    avant de pouvoir laisser votre commenaitre sur cet expert.
                </DialogContentText>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '55ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                <div>
                    <TextField
                        required
                        id="standard-required"
                        label="Nom & Post-nom"
                        onChange={(e) => setIdentite(e.target.value)}
                    />
                    <TextField
                        required
                        id="standard-required"
                        label="Téléphone ou E-mail"
                        onChange={(e) => setContact(e.target.value)}
                    />
                    <TextField
                        id="outlined-password-input"
                        label="Adresse"
                        autoComplete="current-password"
                        onChange={(e) => setAdresse(e.target.value)}
                    />
                    
                </div>
              
                </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={submitData}>Commenter</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snack}
                onClose={snackClose}
                message={message}
                key={vertical + horizontal}
            />
            <Footer/>
        </div>

       

        
    );
}

export default Profile