import React, { useState, useEffect } from 'react';
import axios from '../../services/api/baseUrl';
import swal from "sweetalert";
import { useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import HelmetMeta from '../../components/metas/helmet_meta';
import { CircularProgress } from '@mui/material';
import Footer from '../../components/footer/footer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

function ContactExpert(){

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState(false);
    const [loadingVille, setLoadingVille] = useState(true);
    const [province, setProvince] = useState([]);
    const [open, setOpen] = useState(false);

    //Data from
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('godIsGood=10<#');
    const [phone, setPhone] = useState('');
    const [commune, setCommune] = useState('');
    const [rue, setRue] = useState('');
    const [ville, setVille] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeVille = (event) => {
        setVille(event.target.value);
    };


    const getProvince = () => {
        axios.get("/public/province/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoadingVille(false);
                setProvince(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getProvince();
    }, [])

    const cleanText = () =>
    {
        setName("");
        setDescription("");
        setEmail("");
        setPhone("");
        setVille('');
        setRue('');
        setCommune('');
    }

    const submitData = () => {
   
        if (description !== "" && email !== "" && phone !== "" && id !== "") {
            setLoading(true);
            const data = {
                description: description,
                noms: name,
                email: email,
                phone: phone,
                ville: ville,
                commune: commune,
                rue: rue,
                password: password,
                ref_specialiste: id
            };   
            axios.post('/public/demande/add', data)
            .then(function (json) {

                if(json.data.success === true){
                    setLogin(false);
                    swal({ title: "Succès", icon: 'success', text: json.data.message });
                    cleanText();
                }else{
                    setLogin(false);
                    swal({ title: "Avertissement", icon: 'warning', text: json.data.message});
                }

            }).catch(function (error) {
                setLogin(false);
                swal({ title: "Erreur", icon: 'error', text: error });
            });

        } else {
            alert('Veuillez remplire les champs obligatoires.')
        }
        
    }

    const loginSubmit = () => {
   
        if (password !== "" && email !== "") {
            setLogin(true);
            const data = {
                password: password,
                username: email
            };   
            axios.post('/accounts/login', data)
            .then(function (json) {

                if(json.data.success === true){
                    const dataDemande = {
                        description: description,
                        email: json.data.user.email,
                        phone: json.data.user.phone,
                        ref_specialiste: id
                    };   
                    axios.post('/public/demande/add', dataDemande)
                    .then(function (json) {
        
                        if(json.data.success === true){
                            setLogin(false);
                            swal({ title: "Succès", icon: 'success', text: json.data.message });
                            cleanText();
                        }else{
                            setLogin(false);
                            swal({ title: "Avertissement", icon: 'warning', text: json.data.message});
                        }
        
                    }).catch(function (error) {
                        setLogin(false);
                        swal({ title: "Erreur", icon: 'error', text: error });
                    });
                }else{
                    setLogin(false);
                    swal({ title: "Avertissement", icon: 'warning', text: json.data.message});
                }

            }).catch(function (error) {
                setLogin(false);
                swal({ title: "Erreur", icon: 'error', text: error });
            });

        } else {
            alert('Veuillez entrer vos identifiants de connexion.')
        }
        
    }

   

    return(
        <>
            <HelmetMeta title={"Contacter  l'expert"}/>
            <Header/>
            <div className="container">
                <div className="row">

                    <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">

                        <section id="contact" className="margin-bottom-60">
                            <h3 className="headline margin-top-15 margin-bottom-35">Contactons l'expert !</h3>
                            <div className="account-type">
                                <div>
                                    <input type="radio" name="account-type-radio" id="freelancer-radio" className="account-type-radio" checked/>
                                    <label for="freelancer-radio" className="ripple-effect-dark"><i className="icon-material-outline-account-circle"></i>Je suis un nouveau client</label>
                                </div>

                                <div>
                                    <input type="radio" name="account-type-radio" id="employer-radio" class="account-type-radio"/>
                                    <label onClick={handleClickOpen} for="employer-radio" class="ripple-effect-dark"><i class="icon-material-outline-lock"></i>J'ai déjà un compte</label>
                                </div>
                            </div>
                            <form method="post" name="contactform" id="contactform" autocomplete="on">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-with-icon-left">
                                            <input className="with-border" onChange={(e) => setName(e.target.value)} value={name} name="name" type="text" id="name" placeholder="Identite" />
                                            <i className="icon-material-outline-account-circle"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-with-icon-left">
                                            <input onChange={(e) => setEmail(e.target.value)} value={email} className="with-border" name="email" type="email" id="email" placeholder="Adresse e-mail" pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$" />
                                            <i className="icon-material-outline-email"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-with-icon">
                                    <select onChange={handleChangeVille}>
                                        {  
                                            loadingVille  ?  <option>Ville</option> : 
                                            <>
                                                <option>Ville</option>
                                                {province.data.map(item => (
                                                    <option key={item.idt_provinces}>{item.province}</option>
                                                ))}
                                            </>
                                        }
                                    </select>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-with-icon-left">
                                        <input onChange={(e) => setCommune(e.target.value)} type="text" className="input-text with-border" name="commune-register" value={commune} placeholder="Votre commune de résidence"/>
                                            <i className="icon-material-outline-account-city"></i>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-with-icon-left">
                                        <input onChange={(e) => setRue(e.target.value)} type="text" className="input-text with-border" name="rue-register" value={rue} placeholder="Entrez votre rue/avenue"/>
                                            <i className="icon-material-outline-ville"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-with-icon-left">
                                    <input className="with-border" type="text" id="subject" placeholder="Téléphone" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                    <i className="icon-material-outline-assignment"></i>
                                </div>

                                <div>
                                    <textarea onChange={(e) => setDescription(e.target.value)} value={description} className="with-border" cols="40" rows="5" id="comments" placeholder="Décrivez votre demande" spellcheck="true" required="required"></textarea>
                                </div>
                                {
                                    loading ? <CircularProgress/> : 
                                    <input type="submit" onClick={submitData} className="submit button margin-top-15" id="submit" value="Contacter" />
                                }
                                

                            </form>
                        </section>

                    </div>

                </div>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Connectez-vous !</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Veuillez vous connecter pour contacter l'expert.
                </DialogContentText>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 2, width: '60ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                <div>
                    <br/>
                    <div className="input-with-icon-left">
                        <i className="icon-material-baseline-mail-outline"></i>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" className="input-text with-border" name="emailaddress-register" id="emailaddress-register" placeholder="Adresse e-mail" required/>
                    </div>
                    <div className="input-with-icon-left" title="Doit contenir au moins 8 caractères" data-tippy-placement="bottom">
                        <i className="icon-material-outline-lock"></i>
                        <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" className="input-text with-border" name="password-register" id="password-register" placeholder="Mot de passe" required/>
                    </div>

                    <div>
                        <textarea onChange={(e) => setDescription(e.target.value)} value={description} className="with-border" cols="40" rows="5" id="comments" placeholder="Décrivez votre demande" spellcheck="true" required="required"></textarea>
                    </div>
                </div>
              
                </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                {
                    login ? <CircularProgress/> : <Button onClick={loginSubmit}>Se connecter</Button>
                }
                
                </DialogActions>
            </Dialog>

            <div className="margin-top-70"></div>
            <Footer/>
        </>
    );
}

export default ContactExpert