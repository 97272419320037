import axios from '../../../services/api/baseUrl'
import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Link } from "react-router-dom";

function FeaturesJob()
{

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const getExperts = () => {
        axios.get("/public/experts/all")
        .then(res => 
        {
            if (res.data)
            {
                setLoading(false);
                setData(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getExperts();
    }, [])

    return(
    <div>
        <div className="section gray margin-top-45 padding-top-65 padding-bottom-75">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        
                        <div className="section-headline margin-top-0 margin-bottom-35">
                            <h3>Nos Experts</h3>
                            <a href="#" className="headline-link">Parcourir tous les experts</a>
                        </div>
                        
                        <div className="listings-container compact-list-layout margin-top-35">

                        {  loading  ? <div className="center">
                                            <Skeleton
                                                sx={{ bgcolor: 'grey.900' }}
                                                variant="rectangular"
                                                height={118}
                                            />
                                        </div> : 
                                        <div>
                                            {data.data.map(item => (
                                                 <div className="job-listing with-apply-button" key={item.idt_users}>

                                                        <div className="job-listing-details">
                        
                                                            <div className="job-listing-company-logo">
                                                                <img src="images/user-avatar-placeholder.png" alt={item.noms}/>
                                                            </div>
                        
                                                            <div className="job-listing-description">
                                                                <h3 className="job-listing-title">{item.noms}</h3>
                        
                                                                <div className="job-listing-footer">
                                                                    <ul>
                                                                        <li><i className="icon-material-outline-business"></i> Validé.e <div className="verified-badge" title="Verified Employer" data-tippy-placement="top"></div></li>
                                                                        <li><i className="icon-material-outline-location-on"></i> San Francissco</li>
                                                                        <li><i className="icon-material-outline-business-center"></i>Disponible</li>
                                                                        <li><i className="icon-material-outline-access-time"></i> 2 days ago</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                        
                                                            <Link 
                                                            
                                                                to={{
                                                                    pathname: `/expert/${item.idt_users}`,
                                                                    state: {users: item }
                                                                }}
                                                            ><span className="list-apply-button ripple-effect">Voir le Profile</span></Link>
                                                        </div>
                                                    </div>	
                                                ))
                                            }

                                        </div>
                                    }

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default FeaturesJob