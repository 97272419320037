import Header from "../../components/header/header";
import HelmetMeta from "../../components/metas/helmet_meta";

function Contact(){

        return(
            <>
                <HelmetMeta title={"Nous contacter"}/>
                <Header/>

                <div id="titlebar" className="gradient">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">

                                <h2>Contact</h2>

                                <nav id="breadcrumbs" className="dark">
                                    <ul>
                                        <li><a href="#">Accueil</a></li>
                                        <li><a href="#">Pages</a></li>
                                        <li>Contact</li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="contact-location-info margin-bottom-50">
                                <div className="contact-address">
                                    <ul>
                                        <li className="contact-address-headline">Notre bureau</li>
                                        <li>425 Berry Street, CA 93584</li>
                                        <li>Téléphone (123) 123-456</li>
                                        <li><a href="#">contact@e-solutions-rdc.com</a></li>
                                        <li>
                                            <div className="freelancer-socials">
                                                <ul>
                                                    <li><a href="#" title="Dribbble" data-tippy-placement="top"><i className="icon-brand-dribbble"></i></a></li>
                                                    <li><a href="#" title="Twitter" data-tippy-placement="top"><i className="icon-brand-twitter"></i></a></li>
                                                    <li><a href="#" title="Behance" data-tippy-placement="top"><i className="icon-brand-behance"></i></a></li>
                                                    <li><a href="#" title="GitHub" data-tippy-placement="top"><i className="icon-brand-github"></i></a></li>
                                                
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                                <div id="single-job-map-container">
                                    <div id="singleListingMap" data-latitude="37.777842" data-longitude="-122.391805" data-map-icon="im im-icon-Hamburger"></div>
                                    <a href="#" id="streetView">Street View</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
}

export default Contact;