import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

function NotFond(){
    return(
        <>
        <Header/>
            <div className="container">

                <div className="row">
                    <div className="col-xl-12">

                        <section id="not-found" className="center margin-top-50 margin-bottom-25">
                            <h2>Page 404 <i class="icon-line-awesome-question-circle"></i></h2>
                            <p>Nous sommes désolés, mais la page que vous recherchez n'existe pas.</p>
                        </section>

                        <div className="row">
                            <div className="col-xl-8 offset-xl-2">
                                    <div className="intro-banner-search-form not-found-search margin-bottom-50">
                   
                                        <div className="intro-search-field ">
                                            <input id="intro-keywords" type="text" placeholder="Que cherchez-vous?"/>
                                        </div>

                                        <div className="intro-search-button">
                                            <button className="button ripple-effect">Rechercher</button>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
         
            <div className="margin-top-70"></div>
        <Footer/>
        
        </>
    );
}

export default NotFond;