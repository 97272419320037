import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Category from "../../components/selections/home/category";
import Experts from "../../components/selections/home/experts";
import FeaturesJob from "../../components/selections/home/featuresJob";
import IntroBanner from "../../components/selections/home/intro-banner";
import HelmetMeta from "../../components/metas/helmet_meta";

//Images agences
import agence1 from "../../assets/images/featured-city-01.jpg";
import agence2 from "../../assets/images/featured-city-02.jpg";
import agence3 from "../../assets/images/featured-city-03.jpg";
import agence4 from "../../assets/images/featured-city-04.jpg";

function Home(){
    return(
        <div>
            <HelmetMeta title={"Réseaux d'experts by E-solutions-rdc"}/>
            <Header/>
                <IntroBanner/>
                <Category/>
                <FeaturesJob/>
                <Experts/>

                <div className="section margin-top-65 margin-bottom-65">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-12">
                                <div className="section-headline centered margin-top-0 margin-bottom-45">
                                    <h3>Nos Agences</h3>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="photo-box" data-background-image={agence1}>
                                    <div className="photo-box-content">
                                        <h3>Kinshasa</h3>
                                        <span>12 Agences</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-3 col-md-6">
                                <a className="photo-box" data-background-image={agence2}>
                                    <div className="photo-box-content">
                                        <h3>Kongo Central</h3>
                                        <span>10 Agences</span>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="col-xl-3 col-md-6">
                                <div className="photo-box" data-background-image={agence3}>
                                    <div className="photo-box-content">
                                        <h3>Lumbumbashi</h3>
                                        <span>8 Agences</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <a className="photo-box" data-background-image={agence4}>
                                    <div className="photo-box-content">
                                        <h3>Katanga</h3>
                                        <span>5 Agences</span>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            <Footer/>
        </div>
    );
}

export default Home